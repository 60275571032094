@use 'src/styles/_theme';

.navWrapper{
    min-width: 60vw;
}

.mainNav {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.headerLI {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: theme.$ts-28;
    & a:hover{
        font-weight: 600;
    }
}

.toggleButton {
    margin-right: 1em;
}

@media screen and (min-width: 760px) {
    .nothing {
      display: none;
    }
  }


  // DESKTOP //
  @media screen and (min-width: 1160px) {
    .navWrapper {
        min-width: 40vw;
    }
  }