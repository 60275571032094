@use 'src/styles/_theme';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.aboutCoverImg {
    height: 350px;
    width: auto;
}
.speechBubble {
    position: absolute;
    width: 180px;
    left: 148px;
    top: 26px;
}
.heading1 {
    font-size: theme.$ts-28;
    line-height: 34px;
    margin: 0;
    font-weight: 600;
}
.navWrapper {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    position: relative;
    height: 30px;
}
.nav{
    position: relative;
    left: -40px;
    top: -70px
}
.listItem{
    position: relative;
    font-size: theme.$ts-16;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: theme.$ts-28;
    & a {
        z-index: 1000;
        color: theme.$primary-blue;
    }
    & a:hover{
        font-weight: 600;
    }
}
.linkArrow {
    position: absolute;
    top: 5px;
    right: -22px;
}
.bioWrapper{
    height: 100%;
    h2 {
        color: theme.$primary-blue;
        font-size: theme.$ts-18;
        line-height: theme.$ts-22;
        margin-bottom: theme.$ss-8;
    }
    .pronoun {
        color: theme.$grey;
        font-size: theme.$ts-12;
        font-style: italic;
    }
    p{
        font-size: theme.$ts-14;
        line-height: theme.$ts-24;
        margin-top: theme.$ss-16;
    }
    .blueText {
        color: theme.$primary-blue;
    }
}
.buttonWrapper {
    margin-top: 42px;
    display: flex;
    justify-content: center;
}

.aboutCoverImgContainer{
    position: relative;
    width: 328px;
}
//LARGER SCREENS
@media screen and (min-width: 800px) {
    .containerDT {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .aboutCoverImgContainerDT{
        width: 40%;
        position: relative;
    }
    .bioWrapperDT{
        width: 55%;
        .heading1DT {
            font-size: theme.$ts-28;
            line-height: 38px;
            font-weight: 600;
        }
        h2 {
            color: theme.$primary-blue;
            font-size: theme.$ts-18;
            line-height: theme.$ts-22;
            margin: 0;
        }
        .pronounDT {
            color: theme.$grey;
            font-size: theme.$ts-14;
            line-height: 20px;
            margin-left: 20px;
            font-style: italic;
        }
        p{
            font-size: theme.$ts-16;
            line-height: theme.$ts-24;
            margin-top: theme.$ss-16;
            font-weight: 400;
        }
        .blueTextDT {
            color: theme.$primary-blue;
            font-weight: 600;
        }
    }
    .aboutCoverImgDT {
        height: 312px;
        width: auto;
    }
    .speechBubble {
        width: 156px;
        left: 132px;
        top: 27px;
    }
    .buttonWrapperDT {
        margin-top: 42px;
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width: 900px) {
    .aboutCoverImgDT {
        height: 350px;
        width: auto;
    }
    .speechBubble {
        width: 180px;
        left: 148px;
        top: 26px;
    }
}
@media screen and (min-width: 1100px) {
    .aboutCoverImgDT{
        height: 400px;
    }
    .speechBubble {
        width: 204px;
        left: 170px;
        top: 32px;
    }
}
@media screen and (min-width: 1190px) {
    .aboutCoverImgDT{
        height: 450px;
    }
    .speechBubble {
        width: 229px;
        left: 190px;
        top: 37px;
    }
}
@media screen and (min-width: 1350px) {
    .bioWrapperDT{
        .heading1DT {
            font-size: theme.$ts-40;
            line-height: 49px;
        }
        h2 {
            font-size: theme.$ts-22;
            line-height: theme.$ts-24;
        }
        .pronounDT {
            font-size: theme.$ts-18;
            line-height: 24px;
            margin-left: 24px;
        }
        p{
            font-size: theme.$ts-18;
        }
    }
    .aboutCoverImgDT {
        height: 500px;
    }
    .speechBubble {
        width: 254px;
        left: 211px;
        top: 41px;
    }
    .buttonWrapperDT {
        margin-top: 56px;
    }
}