@use 'src/styles/_theme';

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: theme.$ss-32; 
    margin-bottom: theme.$cardBottomMobile;
 
}

.cardTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: theme.$ss-24; 
    width: 326px;
    height: auto;

    h3 {
        margin: 0;
        text-align: center;
        font-size: theme.$ts-24;
        line-height: theme.$ts-30;
        color: theme.$black;
    }
    h4 {
        margin: 0;
        text-align: center;
        color: theme.$primary-blue;
        font-size: theme.$ts-16;
        line-height: theme.$ts-20;
        font-weight: 600;
        & span {
            color: theme.$black;
            display: block;
            font-weight: 400;
        }
    }
    p {
        margin: 0;
        font-size: theme.$ts-14;
        line-height: theme.$ts-24;
    }
    .lineBreak {
        width: 100%;
        border-top: 1px solid theme.$black;
        margin: 0;
    }
}

// DESKTOP
@media screen and (min-width: 800px) {
    .cardContainer {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: theme.$cardBottomTablet;
        &.flippedContainer{
            flex-direction: row-reverse;
        }
    }
    .imgContainer, .cardTextContainer{
        width: 50%;
        //add min widths
    }
    .cardTextContainer{
        & h3 {
            font-size: theme.$ts-28;
        }
        & h4 {
            font-size: theme.$ts-18;
        }
    }
}
@media screen and (min-width: 970px) {
    .cardTextContainer {
        h4 {
            & span {
                display: inline;
            }
            & span::before {
                content: "-";
                margin-right: 8px;
                margin-left: 8px;
            } 
        }
    }
}



@media screen and (min-width: 1150px) {

    .imgContainer {
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width: 1350px) {
    .imgContainer {
        display: flex;
        justify-content: flex-start;
    }
    .cardContainer {
        margin-bottom: theme.$cardBottomDesktop;
    }
    .cardTextContainer{
        max-width: 544px;
        & h3 {
            font-size: theme.$ts-40;
            line-height: 50px;
        }
        & h4 {
            font-size: theme.$ts-22;
            line-height: 27px;
        }
        p {
            font-size: theme.$ts-18;
            line-height: theme.$ts-24;
        }
    }
}
@media screen and (min-width: 1450px) {
    .imgContainer {
        display: flex;
        justify-content: flex-start;
    }
}