@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "Argithea";
  src: local("Argithea"),
   url("./fonts/argithea/Argithea.ttf") format("truetype");
   font-weight: 400;
}

@font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic"),
   url("./fonts/centurygothic/CenturyGothic.ttf") format("truetype");
   font-weight: 400;
}

/* @font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic Bold"),
   url("./fonts/centurygothic/CenturyGothicBold.ttf") format("truetype");
   font-weight: 500;
} */

body {
  margin: 0;
  font-family:"Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
ADD TO BLURRY IMGS AND MAKE THEM EVEN SIZES
img{
     image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
} */