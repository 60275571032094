@use 'src/styles/_theme';

.footerContainer {
    width: 100vw;
    background-color: theme.$white;
}

.footer {
    width: 100%;
    background-color: white;
    padding: 16px theme.$mobileMargin;
    position: sticky;
    bottom: 0px;
    z-index: 1000;
}
.footerWrapper{
    display: flex;
    justify-content: space-between;
    list-style: none;
    font-size: theme.$ts-12;
}

.basicInfoWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    & p {
        margin: 0;
    }
}

.navLinksWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}
.navLinks {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
}

.icon {
    width: 32px;
    height: 32px;
    &:hover {
        fill: theme.$primary-blue;
    }
}
.name {
    font-size: theme.$ts-16;
    line-height: theme.$ts-20;
    font-weight: 600;
    min-width: 100%;
}
.pronouns2 {
    font-weight: 400;
}

//  TABLET
@media screen and (min-width: 800px) {
    .footer {
        padding: 16px theme.$tabletMargin;
    }
}

@media screen and (min-width: 850px) {
    .footerWrapper {
        flex-direction: column;
        align-items: flex-start;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        padding: 32px theme.$tabletMargin;
        align-items: flex-end;
    }
    .name {
        font-size: theme.$ts-22;
        line-height: theme.$ts-24;
        font-weight: 600;
        min-width: 100%;
    }
    .pronouns2 {
        font-style: italic;
        font-size: theme.$ts-18;
        line-height: theme.$ts-24;
    }
    .title {
        font-size: theme.$ts-18;
        line-height: theme.$ts-24;
    }
}

@media screen and (min-width: 1050px) {
    .footer {
        padding: 16px theme.$smallDesktopMargin;
    }
}

//DESKTOP
@media screen and (min-width: 1350px) {

    .footer {
        padding: 32px 0;
        margin-left: auto;
        margin-right: auto;
        width: theme.$siteWidth;
    }
}