@use 'src/styles/_theme';

.iframe {
    height: 100vh;
    width: 100%;
    margin-bottom: 4em;
}

.nbnbContainer {
    margin: 0 theme.$ss-16;
    padding-bottom: theme.$ss-64;
}

.headingUl {
    & li:last-child, li:nth-child(2){
        margin-left: 1.5em;
    }
}

.headingImg {
    //max-width: 486px;
    width: 56%;
    margin-bottom: 3em;
}

.lineBreak {
    border-top: theme.$grey2;
    margin: theme.$ss-36 0;
}

@media screen and (max-width: 670px) {
    .nbnbContainer{
        & .headingUl {
            border-top: none;
            //margin-top: none;
            padding: 0 !important;
        }
    }

}
@media screen and (max-width: 760px) {
    .nbnbContainer{
        & section {
            margin-top: 1em;
        }
    }

}
@media screen and (min-width: 551px) {
    .headingImg {
        margin-top: 28px;
        margin-bottom: 3em;
    }
}

@media screen and (min-width: 670px) {
    .nbnbContainer {
        margin: 0 theme.$ss-32;
    }
}
@media screen and (min-width: 760px) {
    .headingImg {
        max-width: 300px;
        margin-bottom: 0;
        margin-top: 0;
        width: 50%;
    }
}

@media screen and (min-width: 900px) {
    .nbnbContainer {
        margin: 0 7%;
    }
    .lineBreak {
        margin: 3.5em 0;
    }
    .headingImg {
        max-width: 326px;
    }
}

@media screen and (min-width: 1050px) {
    .nbnbContainer {
        font-size: theme.$ts-18;
        max-width: 1000px;
        margin: auto;
    }
}

@media screen and (min-width: 1286px) {
    .nbnbContainer {
        max-width: 1110px;
        margin: auto;
    }
    .competitiveText {
        width: 45%;
    }
}
  @media screen and (min-width: 1400px) {
    .nbnbContainer {
        max-width: 1210px;
        margin: auto;
    }
    .headingCard {
       justify-content: center;
       gap: 35px;
    }
  }

