.title {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
}
.subTitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: #979797;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 0;
}

.headingTwo {
    margin-top: 0;
    margin-bottom: 2em;
    font-size: 32px;
}

.carouselWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   // width: 100%;
}

.carousel {
    display: flex;
    //align-items: center; now buttons are stretched tho
    justify-content: space-between;
    width: 100%;
    gap: 10px;

}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    justify-content: center;
    padding: 0 1em;

    //width: 36%;
    height: auto;
    min-height: 416px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    min-width: 38%;
    max-width: 38%;
    &:first-of-type {
        margin-right: 1em;
    }
}

.imageContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 300px;
    width: 80%;
}
.image {
    width: auto;
    height: 90%;
    max-height: 100%;
    align-self: center;
    &.campus {
        max-width: 96%;
        height: auto;
    }
}

@media screen and (max-width: 850px) {
    .image {
        max-width: 100%;
        height: auto;
    }
  }