@use 'src/styles/_theme';

.headerWrapper {
    width: 100%;
    background-color: theme.$white;
}

.headerContainer {
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 theme.$mobileMargin;
    background-color: theme.$white;
    color: theme.$black;
    height: 100px;
    width: 100vw;
}

.logo{
    height: 60px;
}

.navToggleButton svg {
    transform: scale(2);
}

a {
    text-decoration: none;
    color: theme.$black;
}

// TABLET
@media screen and (min-width: 800px) {
    .headerContainer {
        padding: 0 theme.$tabletMargin;
    }
}

@media screen and (min-width: 1050px) {
    .headerContainer {
        padding: 0 theme.$smallDesktopMargin;
    }
}

// DESKTOP
@media screen and (min-width: 1350px) {
    .headerContainer {
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        width: theme.$siteWidth;
    }
}