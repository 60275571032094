@use 'src/styles/_theme';

.container {
    display: grid;
    grid-template: 100% / 100%;
}

.imgContainer{
   grid-column: 1 / 2;
   grid-row: 1 /2;
}
.image {
    width: 560px;
    max-width: 970px;
    height: auto;
    margin-left: 50%;
    transform: translateX(-50%);
}

.textContainer{
    z-index: 100;
    grid-column: 1 / 2;
    grid-row: 1 /2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: theme.$ss-14; //suppose to be 16
    font-size: theme.$ts-14;
    line-height: theme.$ts-18;
    & h2 {
        font-size: theme.$ts-28;
        margin: 0;
    }
    p {
        margin: 0;
    }
    & hr, .connectText {
        max-width: 450px;
    }
}
.linksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.headingWrapper {
    display: flex;
    align-items: center;
    gap: theme.$ss-16;

}
.asterisk{
    height: 25px;
    width: 25px;
}
.link{
    display: flex;
    align-items: center;
    gap: theme.$ss-16;
}
.icon{
    height: 32px;
    width: 32px;
}
.lineBreak {
    border-top: theme.$grey2;
    margin: theme.$ss-8 0;
    width: 100%;
}
.blueText {
    color: theme.$primary-blue;
    font-weight: 600;
}

//LARGER SCREENS
@media screen and (min-width: 600px) {
    .image {
        min-width: 560px;
        width: 100%;
    }
}

@media screen and (min-width: 800px) {
    .textContainer {
        gap: 24px;
        margin-bottom: 8%;
        & p, a {
            font-size: theme.$ts-16;
            line-height: theme.$ts-20;
        }
    }
}

@media screen and (min-width: 1000px) {
    .textContainer {
        margin-bottom: 9%;
        & hr, .connectText {
            max-width: 525px;
        }
        & .linksWrapper {
            flex-direction: row;
            width: 525px;
            justify-content: space-between;
        }
    }
}
@media screen and (min-width: 1350px) {
    .textContainer {
        margin-bottom: 7em;
        gap: 32px;
        & h2 {
            font-size: theme.$ts-40;
            line-height: 49px;
        }
        & p, a {
            font-size: theme.$ts-18;
            line-height: theme.$ts-22;
        }
        & hr, .connectText {
            max-width: 586px;
        }
        & .linksWrapper {
            width: 586px;
        }
    }
}

//max-width: 1025px;