@use 'src/styles/_theme';

.mobileResume{
    h2 {
        font-family: "Century Gothic";
        color: theme.$grey;
        font-size: theme.$ts-20;
    }
    h2.second {
        margin-top: 33px;
    }
    .lineBreak{
        border-top: theme.$grey2;
        width: 100%;
    }
    h3 {
        font-size: theme.$ts-16;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 9px;
    }
    h3.firstH3 {
        margin-top: 13px;
    }
    span {
        font-weight: 400;
    }
    h4 {
        font-size: theme.$ts-14;
        font-style: italic;
        font-weight: 400;
        margin: 9px 0;
    }
    ul {
        font-size: theme.$ts-12;
        color: theme.$grey;
    }
    .actLikeLi{
        font-size: theme.$ts-12;
        color: theme.$grey; 
    }
}

.buttonContainer {
    background-color: theme.$white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
    position: sticky;
    bottom: 0;
}

//h3 not first has 20px margin top , first is 13
//second h2 has 33px top margin
//9px btw h3 and h4