* {
  box-sizing: border-box;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  /* overflow-x: hidden; */

}

.App {
  text-align: center;
  /* overflow-x: hidden;  */
}

.hide {
  overflow-y: hidden;
}

.image-gallery-icon.image-gallery-right-nav .image-gallery-svg{ 
  height: 60px;
  
}
.image-gallery-icon.image-gallery-left-nav .image-gallery-svg{
  height: 60px;
  
}
.image-gallery-icon:hover {
  color: #100F0F;
}
.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet.active:hover {
  background-color: #100F0F;
  border-color: #100F0F;
}


/*image-gallery-icon image-gallery-left-nav
class="image-gallery-svg"
class="image-gallery-icon image-gallery-right-nav"
class="image-gallery-icon image-gallery-fullscreen-button"
/* .App-logo { #100F0F
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
