
@use 'src/styles/_theme';

.fffContainer {
    margin: 0 theme.$ss-32;
    padding-bottom: theme.$ss-64;
    font-size: theme.$ts-14;
}

.lineBreak {
    border-top: theme.$grey2;
    margin: theme.$ss-36 0;
}

.smallerEmoji{
    width: 55px;
}

.offsetEmoji {
    top: -10px;
}

.research{
    & div div ul{
        margin-top: 0;
    }
    & div div p:nth-child(2){
        margin-bottom: 0;
    }
}

.centered {
    margin: 1.5em auto 2em;
    font-size: 20px;
}
.underline{
    text-decoration: underline;
    margin-bottom: 0!important;
}
.researchUl{
    margin-top: 0;
    font-weight: 600;
    list-style: inside;
    padding-left: 1em;
}
.ul{
    margin-top: 0;
}
.textCardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: theme.$ts-24;
    font-weight: 600;
    margin-top: 1em;
    & p {
        margin-bottom: 0;
    }
}

.lineHeight{
    line-height: 26px;
    & a {
        color: theme.$link;
    }
}
.imgLink {
    width: 100%;
    //margin: theme.$ss-30 auto 0;
}

.headingUl {
    & li:last-child, li:nth-child(2){
        margin-left: 1.3em;
    }
}

.headingImg {
    width: 80%;
    //min-width: 260px;
    max-width: 420px;
    margin-bottom: 3.5em;
}


/// MAX WIDTH

@media screen and (max-width: 670px) {
    .fffContainer{
        & .headingUl {
            border-top: none;
            //margin-top: none;
            //padding: none !important;
            }
    }
    // .headingImg {
    //     margin-bottom: none;
    // }
}

@media screen and (max-width: 1030px) {
    .headingCard {
        // & h2 {
        //     font-size: 1.8rem;
        // }
    }
}


///// MIN WIDTHS
@media screen and (min-width: 500px){
    .imgLink {
        width: 80%;
        margin: theme.$ss-30 auto 0;
    }
    .research {
        & img {
            width: 85%;
        }
    }
}
@media screen and (min-width: 550px) {
    .textCardContainer{
        font-size: theme.$ts-28;
    }
}

@media screen and (min-width: 670px) {
    .headingImg {
        width: 50%;
        //min-width: 260px;
        max-width: 432px;
    }
}
@media screen and (min-width: 760px) {
    .emojiCardsWrapper{
        display: flex;
        gap: theme.$ss-80;
        margin-top: theme.$ss-40;
    }
  }
  
@media screen and (min-width: 800px) {
    .centered {
        font-size: 22px;
        margin: 1.5em auto 0;
    }
    .textCardContainer{
        font-size: theme.$ts-28;
        margin: theme.$ss-18 0;
        & p {
            margin: theme.$ss-18 0;
        }
    }
}
@media screen and (min-width: 900px) {
    .fffContainer {
        margin: 0 7%;
        // max-width: 1000px;
        // margin: auto;
    }
    .lineBreak {
        margin: theme.$ss-64 0;
    }
  }

  @media screen and (min-width: 1050px) {
    .fffContainer {
        font-size: theme.$ts-18;
        max-width: 1000px;
        margin: auto;
    }
    .imageMarginChange {
        margin-left: 0!important;
        margin-right: theme.$ss-18;
    }
    .mobileSmImg {
        margin: 0;
    }
    .reverse {
        flex-direction: row-reverse !important;
    }
    .userComments{
        & article:first-child img{
            width: 96px;
            margin-bottom: 1.7em;
        }
        & article:nth-child(2) img{
            width: 96px;
            margin-bottom: 2.1em;
        }
        & article img {
            margin-bottom: 1em;
        }
        & article div p:nth-child(2) {
            margin-top: auto;
        }
    }
    .flowsCard{
        & img {
            height: 442px;
        }
    }
    .information{
        & img {
            max-width: 400px;
        }
    }
    .wireframe {
        & img {
        max-width: 450px;
        width: auto;
        }
    }
    .centered {
        font-size: 24px;
    }
  }

  @media screen and (min-width: 1400px) {
    .fffContainer {
        max-width: 1210px;
        margin: auto;
    }
    .wireframe {
        & img {
        max-width: 530px;
        width: auto;
        }
    }
  }