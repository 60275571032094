@use 'src/styles/_theme';

.button {
    border: none;
    background-color: theme.$white;
    &:hover{
        cursor: pointer;
    }
}

.iconButton {
    border: none;
    background-color: theme.$white;
    &:hover{
        cursor: pointer;
    }
    & svg {
        fill: #979797;
    }
}
.blueButton {
    height: 100%;
    background-color: theme.$secondary-blue;
    border: 1px solid theme.$secondary-blue;
    color: theme.$black;
    padding: theme.$ss-14 theme.$ss-24;
    font-size: theme.$ts-14;
    font-weight: 600;
    &:hover{
        background-color: theme.$primary-yellow;
        border: 1px solid theme.$primary-yellow;
        cursor: pointer;
    }
}

.blackButton {
    background-color: theme.$darkGrey;
    border: 1px solid theme.$darkGrey;
    color: theme.$white;
    padding: theme.$ss-14 theme.$ss-48;
    font-size: theme.$ts-14;
    font-weight: 500;
    &:hover{
        background-color: theme.$white;
        border: 1px solid theme.$darkGrey;
        color: theme.$darkGrey;
        cursor: pointer;
    }
}

@media screen and (min-width: 551px) {
    .blackButton, .blueButton {
        font-size: theme.$ts-16;
    }
}