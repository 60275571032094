@use 'src/styles/_theme';

//===== Shared Card Styling =======//
.cardContainer, .cardFourContainer, .cardTwoContainer, .cardFiveContainer, .cardSixContainer {
    margin-top: theme.$ss-40;
    display: flex;
    flex-direction: column;
}
.cardSevenContainer{
    display: flex;
    flex-direction: column;
}
.cardEightContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2em;

}
.center{
    display: flex;
    margin-left: auto;
    margin-right:auto;
}

.heading2 {
    font-size: theme.$ts-24;
    font-family: "Century Gothic";
}

.image {
    width: 100%;
    height: auto;
    margin-top: theme.$ss-24;
}


.image2 {
    width: 100%;
}

.buttonContainer {
    margin: theme.$ss-34 auto theme.$ss-12;
    display: flex;
    justify-content: center;
}

.shadowCardContainer{
    padding: theme.$ss-24 theme.$ss-16;
    box-shadow: 0px 4px 4px theme.$shadow;
}

.divider {
    border-left: thin solid theme.$grey2;
    border-top: none;
}

.noDivider {
    display: none;
}

.lineHeight{
    line-height: 26px;
}

.asterisk{
    height: 25px;
    width: 25px;
}

@media screen and (min-width: 400px) {
    .shadowCardContainer {
        padding: theme.$ss-30 theme.$ss-40;
    }
}

@media screen and (min-width: 800px) {
    .shadowCardContainer {
        padding: theme.$ss-45 theme.$ss-64;
    }
}

.lineBreak {
    border-top: 1px solid theme.$grey2;
    margin: theme.$ss-36 0;
}
.lineBreak2 {
    border-top: theme.$grey2;
    margin: theme.$ss-36 0;
}


// @media screen and (min-width: 1050px) {
//     .heading2 {
//         font-size: theme.$ts-36;
//     }
// }

//===== HeadingCard =======//
.cardheadingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: theme.$ss-40;
}
.headingCardTextWrapper{
    margin-top: theme.$ss-14;
    display: flex;
    flex-direction: column;
    align-items: center;
    //width: 100%;
}
.heading {
    font-size: theme.$ts-40;
    font-weight: 400;
    margin: 0 0 theme.$ss-4;
    text-align: center;
}

.subheading{
    color: theme.$grey;
    margin-top: 0;
    font-size: theme.$ts-24;
    font-family: "Century Gothic";
    text-align: center;
}

.headingLineBreak {
    border-top: theme.$grey2;
    width: 100vw;
}

.headerLi{
    list-style: inside;
    //width: 100%;
    font-size: theme.$ts-14;
    //padding-left: 1.5em;
    //text-indent:-1.5em;
    color: theme.$grey;
    line-height: theme.$ts-28;
}
.headerUl {
    padding: 0;
    margin-bottom: theme.$ss-20;
    // margin-left: auto;
    // margin-right: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //width: 80%;
    // flex-direction: column;
    // justify-content: space-around;
    // align-items: center;
    //flex-wrap: wrap;
    //height: 120px;
    //width: 90%;
    //padding-left: 0;
    // margin-right: auto;
    // margin-left: 0;  
    // & li:last-child, li:nth-child(2){
    //     margin-left: .3em;
    // }
}

.projectCoverImg {
    width: 90%;
    height: auto;
    padding-top: theme.$ss-24;
}

@media screen and (min-width: 500px) {
    .projectCoverImg {
        // width: 80%;
        // max-width: 432px;
        // height: auto;
        padding-top: theme.$ss-24;
        //margin-bottom: 2em;
    }
}
@media screen and (min-width: 551px) {
    .cardheadingContainer{
        flex-direction: row-reverse;
        margin: theme.$ss-80 auto theme.$ss-64;
        //width: 100%;
        justify-content: space-around;
        //align-items: space-between !important;
    }
    .projectCoverImg {
        width: 50%;
        padding: 0;
    }
    .headerLi{
        font-size: theme.$ts-16;
        padding-left: 1.5em;
        text-indent:-1.5em;
        line-height: theme.$ts-30;
    }
    .heading {
        font-size: theme.$ts-48;
    }
    .heading2, .subheading {
        font-size: theme.$ts-28;
    }
}
@media screen and (min-width: 671px) {

    .headerUl {
        padding: theme.$ss-24 0;
        border-top: 1px solid theme.$grey2;
    }
}

@media screen and (min-width: 1050px) {
    .cardheadingContainer{
        //flex-direction: row-reverse;
        margin: theme.$ss-80 auto theme.$ss-64;
        width: 90%;
        //align-items: space-between;

    }
    .projectCoverImg {
       width: 50%;
       padding: 0;
    }
    .heading {
        font-size: 3.5rem;
    }
    .subheading, .heading2{
        font-size: theme.$ts-36;
    }
    .headerLi{
        font-size: theme.$ts-18;
    }
    // .headerUl{
    //     width: 75%;
    //     flex-wrap: wrap;
    // }
}

//===== Overview Card =======//

.overviewHeading{
    margin: 0 0 theme.$ss-14 0;
}

.overviewText {
    line-height: 24px;
}

//===== BulletPoints Card =======//

.bulletCardContainer {
    margin-top: theme.$ss-80;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    
}

.cardArticle{
    border-width: 0px 0.5px 0.5px 0.5px;
    border-style: solid;
    border-color: theme.$grey;
    padding-top: theme.$ss-12;
    &:first-of-type{
        border-top: 0.5px solid theme.$grey;
    }
}

.heading3 {
    font-size: theme.$ts-22; 
    font-family: "Century Gothic";
    border-bottom: 1px dotted theme.$grey;
    text-align: center;
    margin: 0 theme.$ss-30 theme.$ss-8;
}
//turn these into classNames
.cardUl {
    padding-left: theme.$ss-50;
    margin-bottom: theme.$ss-20;
}

.cardLi {
    list-style: inside;
    line-height: 24px;
    font-size: theme.$ts-14;
    padding-left: 1.5em;
    text-indent:-1.5em;
}

@media screen and (min-width: 550px) {
   
}

@media screen and (min-width: 800px) {
    .bulletCardContainer {
        display: flex;
        border: 0.5px solid theme.$grey;
        width: 100%;
    }
    .cardArticle{
        padding-top: theme.$ss-12;
        width: 100%;
        //remove border 
        border: none;
        &:first-of-type{
            border: none;
        }
    }
    .cardUl{
        padding-left: theme.$ss-20;
    }
    .cardLi {
        font-size: theme.$ts-16;
        margin-right: 5px;
        //padding-left: theme.$ss-16;
    }
  }

//===== Emoji Card =======//
.emojiCardContainer{
    margin-top: theme.$ss-40;
    padding: theme.$ss-18 theme.$ss-28 theme.$ss-16;
    background-color: theme.$lighterGrey;
}

.emojiContainer2{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 3em;
}
.emojiContainer3{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 1.5em;
}

.emojiHeadingContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.emojiHeading {
    border-bottom: 1px dotted theme.$grey;
    margin: 0;
    margin-right: theme.$ss-8;
    font-size: theme.$ts-24;
    font-family: "Century Gothic";
}

.emojiImg {
    width: 67px;
    //height: 79px;
    position: relative;
    //top: -20px;
}

.emojiImg2 {
    width: 60px;
}

.emojiText {
    //position: relative;
    margin-top: theme.$ss-8;
    margin-bottom: theme.$ss-16;
    line-height: 24px;
    //top: -18px;
}

.emojiText2Wrapper {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 551px){
    .emojiHeading{
        font-size: theme.$ts-28;
    }
    .emojiImg2 {
        width: 75px;
    }
    // .emojiContainer3{
    //     width: 80%;
    //     margin-left: auto;
    //     margin-right: auto;
    // }
}
@media screen and (min-width: 800px){
    .cardSevenContainer{
        gap: 3em;
    }
    .emojiImg2 {
        width: 90px;
    }
    .emojiContainer2, .emojiContainer3{
        justify-content: space-between;
    }
}
@media screen and (min-width: 1050px){
    // .emojiHeading{
    //     font-size: theme.$ts-32;
    // }
    .emojiImg2 {
        width: 100px;
    }
}

@media screen and (min-width: 980px){
    .emojiCardContainer{
        width: 50%;
    }
}

//===== Research Card  Grid Card=======//

.emojiHeading {
    width: 60%;
    height: min-content;
}

// .researchImg, 
.gridImg {
    width: 100%;
    margin: theme.$ss-8 0;
}

.text2Wrapper{
    margin-bottom: theme.$ss-28;
}

.concernsContainer {
    background-color: theme.$lighterGrey;
    padding: theme.$ss-24 theme.$ss-16;
    box-shadow: 0px 4px 4px theme.$shadow;
}

.concernsHeader {
    text-align: center;
    font-size: theme.$ts-20;
    font-family: "Century Gothic";
    margin-top: 0;
    padding-bottom: theme.$ss-8;
    border-bottom: 1px solid theme.$lightGrey;
}

.researchCardLi{
    line-height: theme.$ts-36;
}

// .researchCardLi {
//     //list-style: disc;
//     &:not(:last-child){
//         padding-bottom: theme.$ss-8;
//     }
// }

@media screen and (min-width: 800px){
    .textImgWrapper, 
    .textImgWrapper2{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .textImgWrapper2{
        margin-top: theme.$ss-50;
        flex-direction: row-reverse;
    }
    //.researchImg, 
    .gridImg, .concernsContainer{
        max-width: 600px;
        width: 40%;
        height: fit-content;
        margin: 0;
    }
    .concernsContainer{
        width: 44%;
    }
    .text2Wrapper,
    .headingTextWrapper {
        width: 47%;
    }
}
//===== User Comments Card =======//
.userCommentsCardContainer {
    margin-top: theme.$ss-40;
    display: flex;
    flex-direction: column;
}

.commentArticle, 
.commentTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: space-between;
}

.commentArticle {
    &:not(:last-child) {
        margin-bottom: theme.$ss-36;
    }
}

.commentTextWrapper{
    height: 100%;
    justify-content: space-between;

}

.userCommentsImg {
    width: 120px;
    margin-bottom: theme.$ss-24;
}

.userCommentsText {
    font-weight: 600;
    font-size: theme.$ts-16;
    margin-bottom: theme.$ss-12;
    text-align: center;
}

.name2{
    color: theme.$grey;
    margin-left: auto;
    display: inline;
    width: fit-content;
}

@media screen and (min-width: 500px){
    .userCommentsCardContainer p{
        max-width: 60%;
    }
}

@media screen and (min-width: 1050px){
    .userCommentsCardContainer {
        flex-direction: row;
        & p {
            max-width: 70%;
        }
    }
    .commentArticle{
        flex: 1;
        &:nth-child(2) {
            border-left: 1px solid theme.$grey2;
            border-right: 1px solid theme.$grey2;
        }
        &:not(:last-child) {
            margin-bottom: 0;
        }
        
    }
    .name {
        min-width: fit-content;
        margin-bottom: 0;
        font-size: theme.$ts-14;
        font-weight: 600;
        color: theme.$grey;
    }
    .name2{
        font-size: theme.$ts-18;
        color: theme.$grey;
        margin-left: auto;
        display: inline;
        width: fit-content;
    }
    .movie{
        margin-top: 0;
        font-size: theme.$ts-14;
        color: theme.$grey;
    }
}
//===== TextBlock =======//
.textBlock{
    background-color: theme.$lightGrey;
    box-shadow: 0px 4px 4px theme.$shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: theme.$ss-24;
    font-size: theme.$ts-24;
    & p{
        font-family: "Century Gothic";
        margin: 0;
        margin-bottom: 0;

    }
}

@media screen and (min-width: 551px){
    .textBlock{
        padding: theme.$ss-28;
        font-size: theme.$ts-28;
    }
}

@media screen and (min-width: 1050px){
    .textBlock{
        padding: theme.$ss-36;
        font-size: theme.$ts-36;
    }
}

//===== Picture Cards =======//
.imageWrapper{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.picCard8Wrapper{
    display: flex;
    flex-direction: column;
}
.picCardText, .picCardText5, .picCardText6{
    & p{
        margin-bottom: theme.$ss-16;
    }
}
.imageWrapper2{
    display: flex;
    justify-content: center;
    gap: 1em;
}
.image3 {
    width: auto;
    height: 400px;
} 
.image6 {
    width: 100%;
}

.image4 {
    width: 100%;
}
.picHeading{
    font-size: theme.$ts-14;
    color: theme.$grey;
    text-transform: uppercase;
}
.picTitle{
    margin-bottom: 0!important;
    margin-top: 0;
    font-size: theme.$ts-24;
}
.image4Container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.picCardFourText{
    display: flex;
    flex-direction: column;
}

.widerImg {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: auto;
}

.cardThreeContainer {
    margin-top: theme.$ss-40;
    display: flex;
    flex-direction: column;
    & .imageWrapper {
        flex-direction: column;
    }
}

.rightSide {
    text-align: end;
    font-size: .8rem;
}

@media screen and (min-width: 500px){
    .image2, .image5 {
        width: 70%;
        margin: theme.$ss-30 auto 0;
    }
    .widerImg {
        width: 400px;
    }
}

@media screen and (min-width: 551px){
    .picTitle {
        font-size: theme.$ts-28;
    }
    .picHeading{
        font-size: theme.$ts-16;
    }    
    .image3 {
        width: auto;
        height: 545px;
    }
}


@media screen and (min-width: 600px){
    .picCardText2{
        max-width: 600px;
        width: 50%;
    }
    .cardTwoContainer{
        flex-direction: row;
        align-items: center;
        justify-content: space-between; 
        flex-wrap: wrap;
        & img, .picCardText, .picCardText5, .picCardText6 {
            max-width: 465px;
            width: 45%;
        }
    }
}
@media screen and (min-width: 800px){
    .cardEightContainer{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 2em;
    }
    .picCard8Wrapper {
        width: 48%;
    }
    .image{
        max-width: 450px;
        width: 45%;
        margin: 0;
    }
    .picCardText, .picCardText5{
        max-width: 600px;
        width: 45%;
        //margin-bottom: auto;
    }
    .picCardText6{
        max-width: 600px;
        width: 50%;
        //margin-bottom: auto;
    }
    .cardContainer{
        flex-direction: row;
        align-items: center;
        justify-content: space-between; 
    }

    .cardFourContainer, .cardFiveContainer, .cardSixContainer, .cardSevenContainer {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .picCardFourTextWrapper{
        width: 50%;
        margin-left: auto;
    }
    .picCardFourTextWrapper2{
        width: 50%;
        margin-right: auto;
    }
    .image4Container{
       align-items: flex-start;
       width: 48%;
       & .image4:nth-child(2){
            margin-top: 1em;
       }
    }
    .picTitle{
        font-size: 1.8rem;
    }
    .widerImg {
        min-width: 420px;
        margin-right: theme.$ss-28;
        width: 43%;
    }
    .imageL{ /// changing for mny
        max-width: 450px;
        margin: 0;
    }    
    .imageWrapper, .imageWrapper2{
        display: flex;
        justify-content: space-around;
        width: 50%;
    }
    .cardThreeContainer {
        & .imageWrapper {
            width: 100%;
        }
    }
    .emojiContainer3 {
        border-top: theme.$grey2;
        margin: theme.$ss-36 0;
    }
}
@media screen and (min-width: 1050px){
    .picTitle{
        margin-bottom: theme.$ss-10;
        font-size: 2rem;

    }
    .picCard8Wrapper {
        width: 50%;
    }
    .cardOneText {
        width: 53%;
    }
    .picCardText{
        max-width: 600px;
        width: 45%;
        //margin-bottom: auto;
    }
    .picCardText5{
        max-width: 700px;
        width: 50%;
        line-height: 24px;
    }
    .picCardText6{
        max-width: 700px;
        width: 53%;
        line-height: 24px;
    }
    .cardThreeContainer {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & p{
            margin-bottom: theme.$ss-16;
        }
        & .imageWrapper {
            width: 50%;
        }
    }

    .textWrapper{
        width: 42%;
        //width: 400px;
    }
    .image{ /// changing for mny
        max-width: 450px;
        width: 45%;
        margin: 0;
    }
    .image7{
        width: 44%;
        max-width: 500px;
    }
    .imageL{ /// changing for mny
        max-width: 450px;
        margin: 0;
    }
    .image2 {
        margin-top: theme.$ss-30;
    }
    .picCardFourTextWrapper{
        width: 50%;
        margin-left: auto;
    }
    .picCardFourTextWrapper2{
        width: 50%;
        margin-right: auto;
    }
    .picCardFourText{
        display: flex;
        flex-direction: column;
    }
    .image4Container{
       align-items: flex-start;
       width: 40%;
       & .image4:nth-child(2){
            margin-top: 1em;
       }
    }
}
@media screen and (min-width: 1200px){
    .picCardText{
        width: 50%;
    }
}

@media screen and (min-width: 1250px){
    .image2 {
        margin-top: theme.$ss-48;
    }
}
@media screen and (min-width: 1400px){
    .image7{
        width: 55%;
        max-width: 550px;
    }
    .picCardText6{
        width: 50%;
    }
}
//===== Prototype Card =======//
@media screen and (min-width: 800px) {
    .prototypeCardContainer{
        flex-direction: row-reverse;
        & p {
            margin-bottom: theme.$ss-18;
        }
        & img {
            width: 50%;
            max-width: none;
            margin-left: 0!important;
        }
    }
   .prototypeTextWrapper{
        width: 45%;
   } 
}


//===== Final Design Card =======//

    .finalDesignCardContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: theme.$ss-56;
        & .image{
            max-width: none;
            //width: 80%;
        }
    }

.finalDesignHeading {
    color: theme.$darkGrey;
    margin-top: 0;
}

.finalImage, .imgCard {
    width: 100%;
    margin: theme.$ss-30 auto 0;
}

.imageOffset {
    position: relative;
    width: 100vw;
    left: -28px;
}

@media screen and (min-width: 900px) {
    .imageOffset{
        width: 100%;
        left: auto;
    }
}

// @media screen and (min-width: 1050px){
//     .finalDesignCardContainer{
//         display: flex;
//         flex-direction: column-reverse;
//         align-items: center;
//         gap: theme.$ss-56;
//         & .image{
//             max-width: none;
//             //width: 80%;
//         }
//     }
// }

.comingSoonContainer {
    display: flex;
    justify-content: center;
    background-color: #E6E6E6;
    color: #323232;
    margin-top: 4em;
    & p{
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        font-style: italic;
    }
}

.comingSoonContainer2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: theme.$primary-blue;
    margin: 4em 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
}