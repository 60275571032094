@use 'src/styles/_theme';

.heroContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: min-content;
    margin: theme.$ss-16 0 theme.$ss-42;
}
.textWrapper {
    font-size: theme.$ts-22;
    line-height: theme.$ts-36;
    font-weight: 600;
}
.text {
    margin: 0;
}
 .yellowText {
    color: theme.$primary-yellow;
    font-style: italic;
}
.yellowText:hover {
    color: theme.$black;
    font-style: normal;
}
.yellowBackground {
    margin-right: 5px;
    background: linear-gradient(to left, theme.$white 50%, theme.$primary-yellow 50%) right;
    background-size: 200% 100%;
    background-position: left;
}

.thoughtfully {
    position: relative;
    margin-right: 5px;

}
.yellowUnderline{
    position: absolute;
    top: 28px;
    left: -1px;
    width: 132px;
    height: 2.2px;
    object-fit: cover;
    object-position: 100% 0;
    //width: 152px;
        // animation: underline-yellow;
    // animation-delay: 3s;
    // animation-iteration-count: 1;
    // animation-duration: 2s ;
    // animation-fill-mode: forwards;
}

.creatively {
    position: relative;
    margin-right: 5px;

}
.wiggleLine{
    position: absolute;
    top: 24px;
    left: -1px;
    width: 106px;
}
.cover {
    z-index: 100;
    position: absolute;
    top: 24px;
    left: -1px;
    width: 106px;
    height: 9.14px;
    background: linear-gradient(to left, theme.$white  50%, rgb(255, 255, 255, 0) 50%) right;
    background-size: 200% 100%;
    background-position: left;
}

.emphatically {
    position: relative;
    display: inline-block;
    margin-left: 5px;
}
.bracketLeft {
    position: absolute;
    left: -12px;
    //height: 32px;
    top: 4px;
    & svg {
        height: 35px;
    }
}
.bracketRight {
    position: absolute;
    right: -12px;
    //height: 32px;
    top: 4px;
    & svg {
        height: 35px;
    }
}

//ANIMATIONS
// .yellowBackground {
//     animation: background-yellow;
//     animation-iteration-count: 1 ;
//     animation-duration: 3s ;
//     animation-fill-mode: forwards;
// }
// .cover {
//     animation: wiggleline-yellow;
//     animation-delay: 5s;
//     animation-iteration-count: 1 ;
//     animation-duration: 2s ;
//     animation-fill-mode: forwards;
// }


.yellowUnderlineWrapper, .wiggleLineWrapper {
    display: inline;
}

// @keyframes underline-yellow {
//     0% { 
//         width: 0px;
//     }
//     100% { 
//         width: 132px;
//     }  
// }


// TABLET //
.heroImg {
    width: 180px;
    height: auto;
    margin-left: 2.5em;
}

@media screen and (min-width: 800px) {
    .heroContainer {
        flex-direction: row-reverse;
        justify-content: center;
    }
    .textWrapper {
        font-size: theme.$ts-28;
        line-height: 3rem;
        max-width: 640px;
    }
    .yellowUnderline{
        top: 35px;
        //width: 162px;
        width: 0;
        height: 2.7px;
    }
    .wiggleLine{
        top: 33px;
        width: 132px;
        // height: 11.39px;
    }
    .cover {
        top: 33px;
        width: 132px;
        height: 11.39px;
    }
    .bracketLeft {
        //left: -6px;
        //height: 38px;
        top: 6px;
        & svg {
            height: 41px;
        }
    }
    .bracketRight {
        //right: -6px;
        //height: 38px;
        top: 6px;
        & svg {
            height: 41px;
        }
    }
    .yellowUnderline{
        animation: underline-yellow;
        animation-delay: 3s;
        animation-iteration-count: 1;
        animation-duration: 2s ;
        animation-fill-mode: forwards;
    }
    .yellowBackground {
        animation: background-yellow;
        animation-iteration-count: 1 ;
        animation-duration: 3s ;
        animation-fill-mode: forwards;
    }
    .cover {
        animation: wiggleline-yellow;
        animation-delay: 5s;
        animation-iteration-count: 1 ;
        animation-duration: 2s ;
        animation-fill-mode: forwards;
        background-position: right;

    }
    @keyframes background-yellow {
        0% { 
            background-position: right;
        }
        100% { 
            background-position: left;
        }  
    }
    @keyframes underline-yellow {
        0% { 
            width: 0px;
        }
        100% { 
            width: 162px;
        }  
    }
    @keyframes wiggleline-yellow {
        0% { 
            background-position: right;
        }
        100% { 
            background-position: left;
        }  
    }
}
@media screen and (min-width: 945px) {
    .textWrapper {
        font-size: theme.$ts-28;
        line-height: 2.5rem;
    }
    .bracketLeft, .bracketRight {
        top: 3px;
    }
}

// DESKTOP
@media screen and (min-width: 1350px) {
    .heroContainer {
        margin-top: 2.5em;
        margin-bottom: 2.5em;
    }
    .textWrapper {
        font-size: theme.$ts-40;
        line-height: theme.$ts-64;
        max-width: 920px; //or 1120
    }
    .heroImg{
       width: 190px;
       margin-left: 3em;

    }
    .yellowUnderline{
        top: 54px;
       // width: 228px;
        width: 0;
        height: 3.81px;
    }
    .wiggleLine{
        top: 46px;
        width: 196px;
        // height: 16.91px;
    }
    .cover {
        top: 46px;
        width: 196px;
        height: 16.91px;
    }
    .bracketLeft {
        position: absolute;
        //left: -7px;
        top: 9px;
        & svg {
            height: 54px;
        }
    }
    .bracketRight {
        position: absolute;
        //right: -7px;
        //height: 52px;
        top: 9px;
        & svg {
            height: 54px;
        }
    }
    @keyframes underline-yellow {
        0% { 
            width: 0px;
        }
        100% { 
            width: 228px;
        }  
    }
}