@use 'src/styles/_theme';

.cardContainer {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    gap: theme.$ss-32; 
    margin-bottom: theme.$cardBottomMobile;
 
}
.notFlippedContainer{
    margin-bottom: 160px;
}
//################ ANIMATION SECTION ######################################
.imgAndAnimationContainer { //relative parent needs w and h set
    position: relative;
    // width: 100%;
    // height: 267px;
    width: 326px; // same as sibling
    height: auto;
    display: flex;
    justify-content: center;
}

 .campusImgWrapper{ // logo
    position: relative; // not sure if setting to relative instead of absolute will cause problems
    top: 0;
    left: -50px;
}

.campusImgWrapper {
    z-index: 1000;
}

.nbnbImgWrapper {
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: relative;
    top: 0;
    left: -50px;
}

.animationContainer { // circle
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    width: 180px;
    position: relative; // not sure if setting to relative instead of absolute will cause problems
    top: 10px;
    left: 50px;
}

.campusWrapper {
    .campusImgWrapper {
        top: 0;
        left: 70px;
    }
    .animationContainer {
        top: -60px;
        left: -67px;
    }
}

.animatedTextWrapper { //text
    //h and w must be the same as for animationContainer
    height: 180px;
    width: 180px;
    border-radius: 50%;
    z-index: -1;
    animation: rotateText 9s linear infinite;

    & p {
        width: 100%;
        height: 100%;
        margin: 0;
        font-size: 18px;
        color: theme.$primary-blue;
    }
    
    & span {
        position: absolute;
        left: 50%;  
        transform-origin: 0 90px; //make half of animatedTextWrapper's size
                                    //also change relative to font size of text
    }
}

@keyframes rotateText {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@media (prefers-reduced-motion) {
    .animatedTextWrapper {
        animation: rotateText 9s linear 0.5;
    }
}

//#################### TEXT SECTION #################################################


.cardTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: theme.$ss-24; 
    width: 326px;
    height: auto;

    h3 {
        margin: 0;
        text-align: center;
        font-size: theme.$ts-24;
        line-height: theme.$ts-30;
        color: theme.$black;
    }
    h4 {
        margin: 0;
        text-align: center;
        color: theme.$primary-blue;
        font-size: theme.$ts-16;
        line-height: theme.$ts-20;
        font-weight: 600;
        & span {
            color: theme.$black;
            display: block;
            font-weight: 400;
        }
    }
    p {
        margin: 0;
        font-size: theme.$ts-14;
        line-height: theme.$ts-24;
    }
    .lineBreak {
        width: 100%;
        border-top: 1px solid theme.$black;
        margin: 0;
    }
}

// TABLET
@media screen and (min-width: 550px) {
    .campusWrapper {
        .campusImgWrapper {
            top: 0;
            left: 27px;
        }
        .animationContainer {
            top: -60px;
            left: -67px;
        }
    }
}

@media screen and (min-width: 800px) {
    .cardContainer {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: theme.$cardBottomTablet;
        &.campusWrapper{
            flex-direction: row-reverse;
        }
    }
    .notFlippedContainer{
        margin-bottom: 170px;
    }
    .imgAndAnimationContainer {
        width: 50%;
        left: -50px; //17px for campus at large screens
        justify-content: flex-start;
    }
    .cardTextContainer{
        width: 50%;
        max-width: 482px;
        & h3 {
            font-size: theme.$ts-28;
        }
        & h4 {
            font-size: theme.$ts-18;
        }
    }
    .campusWrapper {
        .campusImgWrapper {
            top: 0;
            left: 53px;
        }
        .animationContainer {
            top: -60px;
            left: -60px;
        }
    }
}
@media screen and (min-width: 970px) {
    .cardTextContainer {
        h4 {
            & span {
                display: inline;
            }
            & span::before {
                content: "-";
                margin-right: 8px;
                margin-left: 8px;
            } 
        }
    }
}


@media screen and (min-width: 1150px) {
    .animationContainer, .animatedTextWrapper {
        height: 240px;
        width: 240px;
    }
    .animatedTextWrapper {
        & span {
            transform-origin: 0 120px;
        }
    }
    .nbnbImgWrapper {
        left: -100px;
    }
    .campusWrapper {
        .animationContainer {
            top: -88px;
            left: -100px;
        }
    }
}

// DESKTOP
@media screen and (min-width: 1350px) {
    .cardContainer {
        margin-bottom: theme.$cardBottomDesktop;
    }
    .notFlippedContainer{
        margin-bottom: 220px;
    }
    .cardTextContainer{
        max-width: 544px;
        & h3 {
            font-size: theme.$ts-40;
            line-height: 50px;
        }
        & h4 {
            font-size: theme.$ts-22;
            line-height: 27px;
        }
        p {
            font-size: theme.$ts-18;
            line-height: theme.$ts-24;
        }
    }

    .animationContainer, .animatedTextWrapper {
        height: 280px;
        width: 280px;
    }
    .animatedTextWrapper {
        & span {
            transform-origin: 0 140px;
        }
    }
    .projectCardImg {
        left: -150px;
    }
    .campusWrapper {
        .animationContainer {
            top: -112px;
  //left: -114px;
            left: -140px;
        }
        .imgAndAnimationContainer {
            width: 50%;
            left: -80px;
        }
    }
}
// @media screen and (min-width: 1480px) {
//     .campusWrapper {
//         justify-content: center;
//     }
// }