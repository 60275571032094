@use 'src/styles/_theme';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2{
        font-size: theme.$ts-28;
        margin: theme.$ss-14 0;
    }
}

.valuesWrapper{
    display: flex;
    flex-direction: column;
    h3 {
        font-size: theme.$ts-22;
        color: theme.$primary-blue;
        margin:0;
    }
    p {
        width: 331px;
        font-size: theme.$ts-14;
        line-height: theme.$ts-18;
        margin-bottom: 24px;
        margin-top: 24px;
    }
    .lastP {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 450px) {
    .valuesWrapper {
        & p {
            width: 100%;
        }

    }
}
//change container margin sides to 3em at 550?
//LARGER SCREENS
@media screen and (min-width: 800px) {
    .articleWrapper {
        display: grid;
        grid-template: repeat(2, 50%)/ repeat(2, 1fr);
        gap: 1em;
    }
    .valuesWrapper {
        & p {
            font-size: theme.$ts-16;
            line-height: theme.$ts-20;
        }
    }
}
@media screen and (min-width: 880px) {
    .articleWrapper {
        gap: 7%;
    }
}

@media screen and (min-width: 1350px) {
    .container {
        & h2 {
            font-size: theme.$ts-40;
            line-height: 49px;
        }
    }
    .valuesWrapper {
        & h3 {
            font-size: theme.$ts-28;
            line-height: theme.$ts-32;
        }
        & p {
            font-size: theme.$ts-18;
            line-height: theme.$ts-22;
        }
    }
}