
@use 'src/styles/_theme';

.cdContainer {
    margin: 0 theme.$ss-16;
    padding-bottom: theme.$ss-64;
    font-size: theme.$ts-14;
}

.lineBreak {
    border-top: theme.$grey2;
    margin: theme.$ss-36 0;
}
.lineBreak2 {
    border-top: theme.$grey2;
    margin-top: theme.$ss-36;
    margin-bottom: 6.75px;
}
.lineBreak3{
    border-top: theme.$grey2;
    margin: theme.$ss-36 0 theme.$ss-36 50%;
    width: 50%;
}
.redesignGif{
    width: 280px;
    
    margin-left: auto;
    margin-right: auto;
}
.smallerEmoji{
    width: 55px;
}

.offsetEmoji {
    top: -10px;
}

.research{
    & div div ul{
        margin-top: 0;
    }
    & div div p:nth-child(2){
        margin-bottom: 0;
    }
}

.centered {
    margin: 1.5em auto 2em;
    font-size: 20px;
}
.underline{
    text-decoration: underline;
    margin-bottom: 0!important;
}
.researchUl{
    margin-top: 0;
    font-weight: 600;
    list-style: inside;
    padding-left: 1em;
}
.ul{
    margin-top: 0;
}
.textCardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: theme.$ts-24;
    font-weight: 600;
    margin-top: 1em;
    & p {
        margin-bottom: 0;
    }
}

.lineHeight{
    line-height: 26px;
    & a {
        color: theme.$link;
    }
}
.imgLink {
    width: 100%;
    //margin: theme.$ss-30 auto 0;
}

// .headingUl {
//     & li:last-child, li:nth-child(2){
//         margin-left: 1.3em;
//     }
// }

.headingImg {
    width: 80%;
    max-width: 420px;
    margin-bottom: 3.5em;
    margin-top: 1em;
}

.carouselCA {
    width: 100%;
}

.iframe {
    height: 100vh;
    width: 100%;
    margin-bottom: 4em;
}

// .picWithTextCard {
//     & .picWithTextContainer {
//         width: 45%;
//     }
//     & div:nth-child(2){
//         width: 50%;
//         margin-top: 0!important;
//    }
//     & img {
//         width: 100%;
//         max-width: none;
//     }
// }
// .picWithTextCard2 {
//     & .picWithTextContainer {
//         width: 45%;
//     }
//     & div:nth-child(1){
//         width: 38%;
//         margin-top: 0!important;
//    }
//     & img {
//         width: 100%;
//         max-width: none;
//     }
// }

/// MAX WIDTH

@media screen and (max-width: 670px) {
    .cdContainer{
        & .headingUl {
            border-top: none;
            // margin-top: none;
            // padding: none !important;
            // }
        }
    // .headingImg {
    //     margin-bottom: none;
    // }
    }
}

// @media screen and (max-width: 1030px) {
//     .headingCard {
//         // & h2 {
//         //     font-size: 1.8rem;
//         // }
//     }
// }


///// MIN WIDTHS
@media screen and (min-width: 500px){
    .imgLink {
        width: 80%;
        margin: theme.$ss-30 auto 0;
    }
    .research {
        & img {
            width: 85%;
        }
    }
}
@media screen and (min-width: 550px) {
    .textCardContainer{
        font-size: theme.$ts-28;
    }
    
    .imgWrapperCard3, .mobileSmImg, .mobileSmImg2, .carouselCA, .priorityMatrixImg, .priorityMatrixImg2 {
        width: 85%;
        height: auto;
        max-width: 550px;

        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 670px) {
    .headingImg {
       // width: 50%;
        //min-width: 260px;
        max-width: 485px;
    }
    .cdContainer {
        margin: 0 theme.$ss-32;
    }
}

@media screen and (min-width: 759px) {
    .headingImg {
        max-width: 285px;
        margin-top: 0;
        margin-bottom: 0;
    }
    .headingCard {
        & div {
            margin-top: 0;
        }
    }
}
@media screen and (min-width: 800px) {
    .cdContainer {
        & .bulletsContainer {
            & article{
                width: auto;
                // & li {
                //     padding-left: 0;
                // }
                &:first-of-type{
                    margin-left: 1.25em;
                }
            }
            & hr {
                margin: 1.25em;
            }
        }
        & .brandIdentity2 {
            flex-direction: row;
            & > div:first-of-type {
                width: 45%;
            }
        }
        & .imgWrapperCard3 {
            flex-direction: row;
            width: 50%;
            & img {
                width: 50%;
                height: auto;
            }
        }
        & .redesign {
            align-items: normal;
            & .redesignText {
                margin-top: 2em;
            }
        }
    }
    .featureComparison {
        flex-direction: column;
        & .featureComparisonText {
            width: 100%;
            max-width: none;
        }
        & .featureComparisonImg {
            width: 85%;
            max-width: none;
        }
    }
    .card8Text{
        width: 47%;
    }
    .projectBackgroundText {
        width: 50%;
    }
    .mobileSmImg {
        max-width: 450px;
        width: 45%;
        margin: 0;
    }
    .priorityMatrixImg {
        max-width: 400px;
        width: 45%;
        margin: 0;
    }
    .priorityMatrixImg2 {
        margin: 0;
        width: 100%;
        max-width: none;
    }
    .productAuditText, .competitiveText {
        width: 54%;
    }
    .mobileSmImg2{
        max-width: 450px;
        width: 42%;
        margin: 0;
    }
    .carouselCA{
        width: 43%;
        margin: 0;
    }
    .headingCard {
        align-items: center;
    }

    .centered {
        font-size: 22px;
        margin: 1.5em auto 0;
    }
    .textCardContainer{
        font-size: theme.$ts-28;
        margin: theme.$ss-18 0;
        & p {
            margin: theme.$ss-18 0;
        }
    }
    .wireframeImgContainer {
        flex-direction: row;
        width: 50%;
        align-items: flex-start;
        gap: 5px;
        & div {
            width: 40%;
        }
        & div:nth-child(2){
            width: 50%;
            margin-top: 0!important;
       }
    }
    .picWithTextCard {
        & .picWithTextContainer {
            width: 45%;
        }
        & div:nth-child(2){
            width: 50%;
            margin-top: 0!important;
       }
        & img {
            width: 100%;
            max-width: none;
        }
    }
    .picWithTextCard2 {
        & .picWithTextContainer {
            width: 45%;
        }
        & div:nth-child(1){
            width: 38%;
            margin-top: 0!important;
       }
        & img {
            width: 100%;
            max-width: none;
        }
    }
}
@media screen and (min-width: 900px) {
    .cdContainer {
        margin: 0 7%;
        // max-width: 1000px;
        // margin: auto;
    }
    .lineBreak {
        margin: theme.$ss-64 0;
    }
    .headingImg {
        max-width: 325px;
    }
  }

  @media screen and (min-width: 980px) {
    .emojiCardsWrapper{
        display: flex;
        gap: theme.$ss-80;
        margin-top: theme.$ss-40;
    }
    .headingImg {
        max-width: 355px;
    }
  }

  @media screen and (min-width: 1050px) {
    .cdContainer {
        font-size: theme.$ts-18;
        max-width: 1000px;
        margin: auto;
    }
    .card8Text{
        width: 42%;
    }
    .featureComparison {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & .featureComparisonText {
            width: 50%;
        }
        & .featureComparisonImg {
            width: 45%;
            max-width: 553px;
        }
    }
    .imageMarginChange {
        margin-left: 0!important;
        margin-right: theme.$ss-18;
    }
    .mobileSmImg {
        margin: 0;
    }
    .priorityMatrixImg {
        max-width: 400px;
        width: 45%;
        margin: 0;
    }
    .productAuditText, .competitiveText, .featureComparisonText {
        width: 50%;
    }
    .mobileSmImg2{
        max-width: 560px;
        width: 46%;
    }
    .carouselCA{
        max-width: 560px;
        width: 46%;
    }
    .reverse {
        flex-direction: row-reverse !important;
    }
    .userComments{
        & article:first-child img{
            width: 96px;
            margin-bottom: 1.7em;
        }
        & article:nth-child(2) img{
            width: 96px;
            margin-bottom: 2.1em;
        }
        & article img {
            margin-bottom: 1em;
        }
        & article div p:nth-child(2) {
            margin-top: auto;
        }
    }
    .flowsCard{
        & img {
            height: 442px;
        }
    }
    .information{
        & img {
            max-width: 400px;
        }
    }
    // .wireframe {
    //     & img {
    //     max-width: 450px;
    //     width: auto;
    //     }
    // }
    .centered {
        font-size: 24px;
    }
  }

@media screen and (min-width: 1230px) {
    .headingCard {
        width: 100%;
    }
    .headingImg {
        max-width: 540px;
    }
}

//AADDDDD TO OTHER PAGES
@media screen and (min-width: 1285px) {
    .cdContainer {
        max-width: 1110px;
        margin: auto;
    }
    .competitiveText {
        width: 45%;
    }
    .shorten{
        & div {
            //width: 52%;
        }
        & div h1{
            width: 80%;
        }
    }
}
  @media screen and (min-width: 1400px) {
    .cdContainer {
        max-width: 1210px;
        margin: auto;
    }
    .headingCard {
       justify-content: center;
      // gap: 35px;
    }
    .dataVisualization {
        & img {
            max-width: 550px;
            width: 46%;
        }
    }
    .headingImg {
        max-width: 592px;
    }
    // .wireframe {
    //     & img {
    //     max-width: 530px;
    //     width: auto;
    //     }
    // }
  }