@use 'src/styles/_theme';

.homeContainer {
    color: theme.$black;
    display: flex;
    flex-direction: column;
    margin: 0 theme.$mobileMargin;
    font-family: "Century Gothic";
}

.heading2{
    color: theme.$primary-blue;
    font-size: theme.$ts-18;
    line-height: theme.$ts-22;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 40px;
}

.nbnbImg {
    width: auto;
    height: 267px;
}
.campusImg{
    width: 300px;
    height: auto;
}
@media screen and (min-width: 380px) {
    .homeContainer {
        margin: 0 32px;
    }
}
@media screen and (min-width: 550px) {
    .campusImg{
        height: auto;
        width: 369px;
    }
}

@media screen and (min-width: 800px) {
    .homeContainer {
        font-size: 16px;
        margin: 0 theme.$tabletMargin;
    }
    .heading2 {
        font-size: theme.$ts-24;
        line-height: theme.$ts-32;
    }
    .campusImg{
        height: auto;
        width: 300px;
    }
}
@media screen and (min-width: 1050px) {
    .homeContainer {
        margin: 0 theme.$smallDesktopMargin;
    }
}

@media screen and (min-width: 1150px) {

    .nbnbImg {
        width: 70%;
        height: auto;
    }
    .campusImg{
        height: auto;
        width: 400px;
    }
}
@media screen and (min-width: 1350px) {
    .homeContainer {
        font-size: 18px;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        width: theme.$siteWidth;
    }
    .heading2 {
        font-size: theme.$ts-28;
        margin-top: 80px;
        margin-bottom: 80px;
    }
    .nbnbImg {
        width: auto;
        height: 422px;
    }
    .campusImg{
        height: auto;
        width: 501px;
    }
}