@use 'src/styles/_theme';

.mnyContainer {
    margin: 0 theme.$ss-16;
    padding-bottom: theme.$ss-64;
    font-size: theme.$ts-14;
    & .marginZero {
        margin: 0;
    }
}

.lineBreak {
    border-top: 1px solid theme.$grey2;
    margin: theme.$ss-36 0;
}
.lineBreak2{
    border-top: 1px solid theme.$grey2;
    margin-top: theme.$ss-36;
}

.bold{
    font-weight: 600;
}

.removeTop{
    margin-top: 0;
}

.protoImg{
    width: 80%;
    align-self: center;
}

.underline{
    text-decoration: underline;
    margin-bottom: 0!important;
}

.marginZero{
    line-height: 26px;
}

.lineHeight{
    line-height: 26px;
    & a {
        color: theme.$link;
    }
}

.leftPadding{
    padding-left: 20px;
}

.researchUl{
    margin-top: 0;
    font-weight: 600;
    list-style: inside;
    padding-left: 1em;
    padding-left: 1.5em;
    text-indent:-1.5em;
}
.headingPic{
    width: 60%;
    min-width: 260px;
    max-width: 315px;
    margin-bottom: 3.5em;
}
.headingUl {
    & li:last-child, li:nth-child(2){
        margin-left: .3em;
    }
}

.competitiveAnalysisCard {
    flex-direction: column;
    & *{
        width: 100%;
        max-width: none;
    }
    & img {
        margin-left: 0 !important;
    }
}


.smallerEmoji{
    width: 55px;
}

.offsetEmoji {
    top: -10px;
}
.shadowContainer{
    padding: theme.$ss-24 theme.$ss-16;
    box-shadow: 0px 4px 4px theme.$shadow;
}

@media screen and (min-width: 551px){
    .mnyContainer{
        & .incrImage{
            width: 100%;
        }
        & .headingPic{
            width: 38%;
            //margin-bottom: none;
        }
    }
    .protoImg{
        width: 70%;
    }
    .mnyContainer{
        & .headingUl {
            padding: theme.$ss-24 0;
            border-top: 1px solid theme.$grey2;
            }
    }
}

@media screen and (min-width: 760px) {
    .emojiCardsWrapper{
        display: flex;
        gap: theme.$ss-80;
        margin-top: theme.$ss-40;
    }
}

@media screen and (min-width: 900px) {
    .mnyContainer {
        margin: 0 7%;
        & .headingPic{
            width: 38%;
        }
    }
    .lineBreak {
        margin: theme.$ss-64 0;
    }
    .row{
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        & .protoImg{
            width: 45%;
        }
        & div {
            width: 51%;
        }
    }
  }

  @media screen and (min-width: 980px) {
    .mnyContainer{
        & p {
            //margin-bottom: theme.$ss-18;
        }
    }
    .reducePad{
        padding: 1.7em;
    }
  }

  @media screen and (min-width: 1050px) {
    .mnyContainer{
        font-size: theme.$ts-18;
        max-width: 1000px;
        margin: auto;
        & .headingPic{
            width: 45%;
        }
    }
    .competitiveAnalysisCard {
        flex-direction: row;
        & img {
            width: 43%;
        }
        & > div {
            width: 45%;
            max-width: 600px;
        }
    }
    .reverse {
        flex-direction: row-reverse !important;
    }
    .userComments{
        & article:last-child div p:first-child{
            position: relative;
            top: -7px;
        }
    }
    .protoImg{
        width: 29% !important;    
    }
    .leftPadding{
        padding-left: 40px;
    }
  }
  @media screen and (min-width: 1285px) {
    .mnyContainer {
        max-width: 1110px;
        margin: auto;
    }
}
  @media screen and (min-width: 1400px) {
    .mnyContainer {
        max-width: 1210px;
        margin: auto;
    }
  }


// @media screen and (min-width: 1000px) {
//     .mnyContainer {
//         margin: 0 13%;
//     }
//     .emojiwrapper{
//         display: flex;
//         gap: theme.$ss-80;
//     }
//   }

//   @media screen and (min-width: 1200px) {
//     .mnyContainer {
//         width: 950px;
//         margin: auto;
//     }
 
//   }