@use 'src/styles/_theme';

.googleContainer {
    margin: 0 theme.$ss-32;
    padding-bottom: theme.$ss-64;
    font-size: theme.$ts-14;
}

.lineBreak {
    border-top: theme.$grey2;
    margin: theme.$ss-36 0;
}

.smallerEmoji{
    width: 55px;
}

.offsetEmoji {
    top: -10px;
}

span a{
    color: theme.$link;
    text-decoration: underline;
}
.researchUl{
    padding: 0;
    font-weight: 600;
    & li:first-child{
        margin-top: 5px;
    }
}
.researchLi{
    list-style: inside;
    padding-left: 1.5em;
    text-indent:-1.5em
}

.underline{
    text-decoration: underline;
}

.lineHeight{
    line-height: 26px;
    
}

.bigger{
    font-size: theme.$ts-16;
}

.bigger.researchUl{
    margin-bottom: 0;
}

.imgLink {
    width: 100%;
    margin-top: theme.$ss-30;
}

.competitiveAnalysisCard {
    flex-direction: column;
    & *{
        width: 100%;
        max-width: none;
    }
}

.headingUl {
    & li:last-child, li:nth-child(2){
        margin-left: .5em;
    }
    & li {
    padding-left: 1.5em;
    text-indent:-1.5em;
    }
}
.headingImg {
    width: 87%;
    //min-width: 260px;
    max-width: 420px;
    margin-bottom: 3.5em;
}

///// MIN WIDTHS
@media screen and (min-width: 500px){
    .imgLink {
        width: 80%;
        margin: theme.$ss-30 auto 0;
    }
}

// @media screen and (max-width: 670px) {
//     .headingImg {
//         margin-bottom: none;
//     }
// }
@media screen and (min-width: 760px) {
    .emojiCardsWrapper{
        display: flex;
        gap: theme.$ss-80;
        margin-top: theme.$ss-40;
    }
  }
@media screen and (min-width: 900px) {
    .googleContainer {
        margin: 0 7%; ///UPDATE OTHER PAGES
    }
    .lineBreak {
        margin: theme.$ss-64 0;
    }
   
}

  @media screen and (min-width: 1050px) {
    // .headingImg{
    //     max-width: 494px;
    // }
    .googleContainer {
        font-size: theme.$ts-18;
        max-width: 1000px;
        margin: auto;
        & h1 {
            font-size: 3.5rem;
        }
    }
    .headingImg {
        min-width: 500px;
    }
    .imageMarginChange {
        margin-left: 0!important;
        margin-right: theme.$ss-18;
    }
    .mobileSmImg {
        margin: 0;
    }
    .competitiveAnalysisCard {
        flex-direction: row-reverse !important;
       
        flex-direction: row;
        & img {
            width: 43%;
        }
        & > div {
            width: 45%;
            max-width: 600px;
        }
        
    }
    .userComments{
        & article:first-child img{
            width: 100px;
        }
        & article img {
            margin-bottom: 1em;
        }
        & article div p:last-child {
            margin-top: 1em;
        }
    }
    .bigger{
        font-size: theme.$ts-20;
    }
  }
  
//   @media screen and (min-width: 1250px) {
//     .googleContainer {
//         & h1 {
//             font-size: 3.5rem;
//         }
//     }
//   }
  @media screen and (min-width: 1400px) {
    .googleContainer {
        max-width: 1210px;  ///UPDATE OTHER PAGES
        margin: auto;
    }
  }