@use 'src/styles/_theme';

.cardContainer {
    display: flex;
    flex-direction: column;
    & .heading3{
        margin: theme.$ss-16 0;
        font-size: theme.$ts-28;
        line-height: 34px;
        margin: theme.$ss-18 0;
    }
    & article {
        display: flex;
        flex-direction: column;
    }
}
.aboutList{
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0;
}
.spanTitle {
    color: theme.$primary-blue;
    font-size: theme.$ts-18;
    line-height: theme.$ts-24;
    font-weight: 600;
}
.spanText {
    font-size: theme.$ts-14;
    line-height: theme.$ts-24;
    display: block;
    margin: 7px 0 28px theme.$ts-32;
}
.aboutCoverImgWrapper {
    display: flex;
    justify-content: center;
}
.aboutCoverImg {
    width: 100%;
    height: auto;
    max-width: 325px;
    margin: theme.$ss-32 0;
}


//LARGER SCREENS
@media screen and (min-width: 800px) {
    .cardContainer {
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
    }
    .spanText {
        font-size: theme.$ts-16;
    }
    .aboutCoverImgWrapper {
        display: inline;
        //justify-content: center;
    }
    .aboutCoverImg {
        max-width: 480px;
        width: 90%;
    }
}

@media screen and (min-width: 1150px) {
    .aboutCoverImg {
        //max-width: 480px;
        width: 100%;
    }
}

@media screen and (min-width: 1350px) {
    .cardContainer {
        .heading3 {
            font-size: theme.$ts-40;
            line-height: 49px;
        }
    }
    .spanTitle {
        font-size: theme.$ts-22;
        line-height: theme.$ts-24;
    }
    .spanText {
        font-size: theme.$ts-18;
        line-height: theme.$ts-24;
    }
}