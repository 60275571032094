
.path {
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
   // stroke-dashoffset: 100;
    animation: dash;
    animation-delay: 7s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}



.path2 {
    stroke-dasharray: 100;
    stroke-dashoffset: 0;
   // stroke-dashoffset: -100;
    animation: dashTwo;
    animation-delay: 8s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
@media screen and (min-width: 800px) {
    .path {
        stroke-dashoffset: 100;
    }
    .path2 {
        stroke-dashoffset: -100;
    }
    @keyframes dash {
        from {
            stroke-dashoffset: 100;
        }
        to {
            stroke-dashoffset: 0;
        }
    }
    @keyframes dashTwo {
        from {
            stroke-dashoffset: -100;
        }
        to {
            stroke-dashoffset: 0;
        }
    }
}