
$primary-color: #0d8484;
$primary-blue: #00A5B7;
$secondary-blue: #AADEE3;
$primary-yellow: #F8C83C;
$white: #ffffff;
$shadow: #D9D9D9;
$lightGrey: #E3E3E3;
$lighterGrey: #F6F6F6;
$grey2: #e3e3e3;
$grey: #979797;
$link: #0F70E3;
//e3e3e3
$darkGrey: #100F0F;
$blackish: #323232;
$black: #000000;

$siteWidth: 1280px;
$mobileMargin: 2em;
$tabletMargin: 60px;
$smallDesktopMargin: 90px;

$cardBottomMobile: 120px;
$cardBottomTablet: 160px;
$cardBottomDesktop: 180px;

//$heading-font-family: 'Montserrat', sans-serif;

$ts-4: 0.25rem;
$ts-8: 0.5rem;
$ts-12: 0.75rem;
$ts-14: 0.875rem;
$ts-16: 1rem;
$ts-18: 1.125rem;
$ts-20: 1.25rem;
$ts-22: 1.375rem;
$ts-24: 1.5rem;
$ts-25: 1.563rem;
$ts-28: 1.75rem;
$ts-30: 1.875rem;
$ts-32: 2rem;
$ts-36: 2.25rem;
$ts-38: 2.375rem;
$ts-40: 2.5rem;
$ts-44: 2.75rem;
$ts-48: 3rem;
$ts-54: 3.375rem;
$ts-64: 4rem;
$ts-72: 5rem;

$ss-2: 0.125em;
$ss-3: 0.188em;
$ss-4: 0.25em;
$ss-5: 0.3125em;
$ss-6: 0.375em;
$ss-8: 0.5em;
$ss-10: 0.625em;
$ss-12: 0.75em;
$ss-11: 0.688em;
$ss-14: 0.875em;
$ss-16: 1em;
$ss-18: 1.125em;
$ss-20: 1.25em;
$ss-24: 1.5em;
$ss-28: 1.75em;
$ss-30: 1.875em;
$ss-32: 2em;
$ss-34: 2.125em;
$ss-35: 2.188em;
$ss-36: 2.25em;
$ss-38: 2.375em;
$ss-40: 2.5em;
$ss-42: 2.625em;
$ss-45: 2.813em;
$ss-48: 3em;
$ss-50: 3.125em;
$ss-56: 3.5em;
$ss-64: 4em;
$ss-80: 5em;

$hs-1: $ts-48;
$hs-2: $ts-40;
$hs-3: $ts-36;
$hs-4: $ts-28;
$hs-5: $ts-22;
$hs-6: $ts-12;

$px-24: 24px;
$px-44: 44px;