@use 'src/styles/_theme';

.aboutContainer{
    display: flex;
    flex-direction: column;
    margin-left: theme.$mobileMargin;
    margin-right: theme.$mobileMargin;
    margin-top: 30px; // 101 in wireframe - 16 from nav padding
    gap: 64px;
}






@media screen and (min-width: 800px) {
    .aboutContainer {
        //font-size: 16px;
       // margin-left: 60px; but it makes it feel too thick
       // margin-right: 60px;
        //margin-top: 85px; // 101 in wireframe - 16 from nav padding
        font-size: 16px;
        margin: 0 theme.$tabletMargin;
    }
}
@media screen and (min-width: 1050px) {
    .aboutContainer {
        margin: 0 theme.$smallDesktopMargin;
    }
}

@media screen and (min-width: 1350px) {
    .aboutContainer {
        font-size: 18px;
        margin-top: 2.5em;
        margin-left: auto;
        margin-right: auto;
        width: theme.$siteWidth;
    }
}