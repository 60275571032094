@use 'src/styles/_theme';

.container{
    & h2{
        font-size: theme.$ts-28;
        line-height: 34px;
        margin: theme.$ss-18 0;
    }
}
.graphicContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: theme.$ss-16;
    color: theme.$primary-blue;
    font-size: theme.$ts-14;
    line-height: theme.$ts-20;
    margin-bottom: 3em; //5.5em
    & span {
        font-size: theme.$ts-16;
        line-height: theme.$ts-20;
    }
}
.arrow{
    width: 42px;
    height: 40px;
    transform: rotate(-90deg);
}
.bold{
    font-weight: 600;
}

.textContainer {
    font-size: theme.$ts-14;
    line-height: theme.$ts-24;
}
.blueText {
    color: theme.$primary-blue;
}
.buttonWrapper {
    font-size: theme.$ts-16;
    line-height: theme.$ts-20;

    margin-top: theme.$ss-56;
    display: flex;
    justify-content: center;
    & .button {
        padding: theme.$ts-14 theme.$ts-24;
    }
}

//LARGER SCREENS
@media screen and (min-width: 800px) {
    .textContainer {
        font-size: theme.$ts-16;
        line-height: theme.$ts-20;
    }
    .articleWrapper {
        display: grid;
        grid-template-areas: "t g";
        gap: 9%;

    }
    .graphicContainer {
        //width: 45%;2/3;
       // height: 100%;
       font-size: theme.$ts-16;
       line-height: theme.$ts-20;
        grid-area: g;
        justify-content: space-between;
        & span {
            font-size: theme.$ts-18;
            line-height: theme.$ts-20;
        }
    }
    .arrow {
        height: 18px;
        width: auto;
        //margin-right: 34px;
    }
    .textContainer {
        grid-area: t;
        max-width: 630px;
        //max-width: 554px;

    }
}
@media screen and (min-width: 880px) {
    .articleWrapper {
        gap: 10%;
    }
}
@media screen and (min-width: 1150px) {
    .graphicContainer {
        & span {
            font-size: theme.$ts-22;
            line-height: theme.$ts-24;
        }
    }
}
@media screen and (min-width: 1350px) {
    .container {
        & h2 {
            font-size: theme.$ts-40;
            line-height: 49px;
        }
    }
    .textContainer {
        font-size: theme.$ts-18;
        line-height: theme.$ts-22;
        max-width: 720px;
    }
    .graphicContainer {
        font-size: theme.$ts-18;
        line-height: theme.$ts-24;
        & span {
            font-size: theme.$ts-28;
            line-height: 34px;
        }
       // margin-bottom: theme.$ss-56;
    }
}//48 or 34