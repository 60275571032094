@use 'src/styles/_theme';

.mobileNav{
    z-index: 5000;
    height: calc(100vh - 100px);
    width: 100vw;
    display: flex;
    font-size: theme.$ts-24;
    padding-left: 32px;
    background-color: theme.$white;
    position: fixed;
    left: 0;
    transition: transform 1s cubic-bezier(0, .52, 0, 1);
    &.show {
        transform: translate3d(100vw, 0, 0);

    }
}

.navUL{
    padding: 0;
    display: flex;
    flex-direction: column;
    //align-items: left;
}

.headerLI {
    list-style: none;
    line-height: theme.$ts-32;
}
